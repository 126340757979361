<template>
  <div class="login">
    <div class="bg">
      <img class="fs" src="../assets/image/login/bgs.png" :style="tops">
      <img class="f" src="../assets/image/login/bgs.png" :style="top">
    </div>
    <div class="main">
      <div class="main1">
        <span class="txt1">+86</span>
        <input v-model="phone" placeholder="请输入手机号码"/>
      </div>
      <div class="main2">
        <input v-model="code"/>
      </div>
      <div class="main3" @click="set">{{ StartText }}</div>

      <div class="main5" @click="login">立即登陆</div>
      <div class="main6">
        <input name="Fruit" type="checkbox" v-model="lawStart"/>
        <span class="word">我已阅读并同意<a @click="getlaw('protocol')">用户协议</a>及<a @click="getlaw('privacy')">隐私政策</a></span>
      </div>
      <!--      <div class="law">-->
      <!--        <p @click="getlaw('privacy')">隐私政策</p>-->
      <!--        <p @click="getlaw('protocol')">用户协议</p>-->
      <!--      </div>-->
    </div>
    <div class="name" v-if="viewName">
      <div class="mains">
        <div class="reset" @click="closeName"></div>
        <div class="title">请输入笔名</div>
        <input type="text" v-model="name">
        <div class="ok" @click="setName">完成</div>
        <div class="f">注：第一次注册需要输入笔名，否则无法进入</div>
        <div class="f">笔名不超过十个字数</div>
      </div>
    </div>
    <load ref="load"></load>
    <law ref="law"></law>
  </div>
</template>

<script>
import {Dialog, Notify} from 'vant'
//inspectCode
import {setCode, setName, visitAdd, inspectCode} from '../api/login'
import load from '../components/load'
import law from "./law";

export default {
  created() {
    let userInfos = this.$cookies.get('userInfo')
    if (userInfos) {
      this.$store.state.userInfo = userInfos
      this.$router.push('/exhibit')
    }

    let userInfo = this.$store.state.userInfo.PhoneMobile
    visitAdd().then()
    if (userInfo) {
      this.$router.push('/exhibit')
    }
  },
  data() {
    return {
      topSt: 0,
      top: '',
      tops: '',
      time: 0,
      StartText: '发送验证码',
      phone: '',
      code: '',
      name: '',
      Start: false,
      lawStart: false,
      viewName: false,
      userInfo: {},
      fn: '',
      fns: ''
    }
  },
  beforeDestroy() {
    clearInterval(this.bctime)
  },
  mounted() {
    this.getbc()
  },
  methods: {
    getbc() {
      this.bctime = setInterval(() => {
        let topSt = this.topSt
        if (topSt < -200) {
          topSt = 0
        }
        topSt = topSt - 0.03
        this.topSt = topSt
        this.top = 'top:' + topSt + '%'
        this.tops = 'top:' + (topSt + 100) + '%'
      }, 10)
    },
    setName() {
      let Name = this.name
      if (Name.length > 10) {
        Dialog.alert({
          message: '笔名不能超过十个字',
        });
      } else {
        let PhoneMobile = this.userInfo.PhoneMobile
        this.$refs.load.open()
        setName({Name, PhoneMobile}).then(res => {
          this.$refs.load.close()
          if (res.code == 0) {
            if (res.first == 1) {
              this.$store.state.userInfo = res.userInfo
              this.$cookies.set('userInfo', res.userInfo)
              localStorage.setItem("user", JSON.stringify(res.userInfo));
              this.$router.push('/introduce')
            } else {
              this.$store.state.userInfo = res.userInfo
              this.$cookies.set('userInfo', res.userInfo)
              localStorage.setItem("user", JSON.stringify(res.userInfo));
              this.$router.push('/exhibit')
            }
          } else {
            // this.$cookies.set('userInfo', res.userInfo)
            Notify({type: 'warning', message: res.message});
          }
        }).catch(()=>{
          this.$refs.load.close()
          Dialog.alert({
            message: '服务端故障，请与技术人员联系',
          });
        })
      }
    },
    set() {
      // if (!this.lawStart) {
      //   Dialog.alert({
      //     message: '请勾选协议政策',
      //   });
      //   return;
      // }

      let time = this.time
      if (!this.phone) {
        Dialog.alert({
          message: '未输入手机号码',
        });
        return;
      }
      if (time == 0) {
        if (/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(this.phone)) {

          let PhoneMobile = this.phone
          this.$refs.load.open()
          setCode({PhoneMobile}).then((res) => {
            this.$refs.load.close()
            if (res.code == 0) {
              Dialog.alert({
                message: res.message,
              });
              this.time = 60
              this.Start = true
              this.CodeTime = setInterval(() => {
                let times = this.time
                times = times - 1
                if (times == 0) {
                  clearInterval(this.CodeTime)
                  this.StartText = '请发送验证码'
                  this.time = 0
                  return
                }
                this.time = times
                this.StartText = times + '秒再次发送'
              }, 1000)
            } else {
              Dialog.alert({
                message: res.message,
              });
            }
          }).catch(()=>{
            this.$refs.load.close()
            Dialog.alert({
              message: '服务端故障，请与技术人员联系',
            });
          })
        } else {
          Dialog.alert({
            message: '手机号码不符合规则',
          });
        }
      }
    },
    closeName() {
      this.viewName = false
      this.name = ''
    },
    login() {
      if (!this.lawStart) {
        Dialog.alert({
          message: '请勾选协议政策',
        });
        return;
      }

      let PhoneMobile = this.phone
      let Code = this.code

      if (!PhoneMobile) {
        Dialog.alert({
          message: '请填写手机号码',
        });
        return;
      }

      if (!Code) {
        Dialog.alert({
          message: '请填写验证码',
        });
        return;
      }

      this.$refs.load.open()
      inspectCode({PhoneMobile, Code}).then(res => {
        this.$refs.load.close()
        if (res.code == 0) {
          let userInfo = res.userInfo
          localStorage.setItem("user", JSON.stringify(userInfo));
          let Name = userInfo.Name
          if (Name) {
            this.$store.state.userInfo = res.userInfo
            localStorage.setItem("user", JSON.stringify(userInfo));
            this.$router.push('/exhibit')
          } else {
            this.viewName = true
            this.userInfo = res.userInfo
            localStorage.setItem("user", JSON.stringify(userInfo));
          }
        } else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(()=>{
        this.$refs.load.close()
        Dialog.alert({
          message: '服务端故障，请与技术人员联系',
        });
      })
    },
    getlaw(str) {
      this.$refs.law.init(str)
    }
  },
  components: {
    [Notify.Component.name]: Notify.Component,
    load,
    law
  }
}
</script>

<style scoped lang="scss">
.login {

  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .bg {
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    .f {
      width: 100%;
      position: absolute;
    }

    .fs {
      position: absolute;width: 100%;
    }
  }

  .name {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .mains {
      height: 210px;
      width: 300px;
      background-color: rgba(249, 249, 249, 0.97);
      box-shadow: 0 1px 8px 0 rgba(143, 143, 143, 0.5);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -150px;
      margin-top: -105px;

      .title {
        width: 100%;
        height: 30px;
        font-size: 13px;
        text-align: center;
        //color: #898989;
        line-height: 30px;
      }

      input {
        display: block;
        margin: 0 auto;
        margin-top: 10px;
        border: none;
        width: 250px;
        height: 40px;
        outline: none;
        font-size: 14px;
        text-align: center;
      }

      .ok {
        width: 120px;
        height: 35px;
        line-height: 35px;
        background-color: #973733;
        color: #FFFFFF;
        text-align: center;
        margin: 0 auto;
        margin-top: 20px;
      }

      .reset {
        background-image: url("../assets/image/map/reset.png");
        background-size: 100% 100%;
        position: absolute;
        right: 10px;
        top: 5px;
        width: 15px;
        height: 15px;
      }

      .f {
        margin-top: 8px;
        font-size: 12px;
        color: #898989;
        text-indent: 1rem;
      }

    }

  }

  .main {
    height: 300px;
    width: 310px;
    background-color: rgba(249, 249, 249, 0.97);
    box-shadow: 0 1px 8px 0 rgba(143, 143, 143, 0.5);
    align-self: center;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -155px;
    margin-top: -150px;

    .law {
      width: 257px;
      height: 50px;
      position: absolute;
      font-size: 14px;
      top: 260px;
      left: 26px;
      line-height: 25px;

      p {
        margin: 0;
        padding: 0;
        color: rgba(48, 157, 196, 0.81);
        text-decoration: underline;
        display: inline;
        margin-right: 10px;
      }
    }

    .main1 {
      height: 40px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      width: 267px;
      position: absolute;
      left: 20px;
      top: 30px;
      position: relative;

      .txt1 {
        width: 40px;
        height: 40px;
        display: block;
        color: rgba(210, 210, 210, 1);
        font-size: 14px;
        line-height: 40px;
        position: absolute;
        left: 0;
        text-align: center;
      }

      input {
        top: 0;
        position: absolute;
        border: none;
        right: 5px;
        width: 210px;
        height: 40px;
        outline: none;
        font-size: 14px;
        padding: 0;
      }
    }

    .main2 {
      width: 139px;
      height: 40px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      position: absolute;
      left: 20px;
      top: 90px;

      input {
        top: 0;
        position: absolute;
        border: none;
        left: 0;
        width: 100%;
        height: 100%;
        outline: none;
        text-indent: 1rem;
        font-size: 13px;
        padding: 0;
      }
    }

    .main3 {
      width: 107px;
      height: 40px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      position: absolute;
      left: 180px;
      top: 90px;
      color: rgba(210, 0, 0, 1);
      font-size: 14px;
      text-align: center;
      line-height: 40px;
    }

    .main4 {
      width: 264px;
      height: 30px;
      position: absolute;
      left: 25px;
      top: 150px;
      display: flex;
      justify-content: space-between;

      .txt {
        font-size: 14px;
        color: rgba(0, 0, 0, 1);
      }

      .info {
        font-size: 14px;
        color: rgba(173, 173, 173, 1);
      }
    }

    .main5 {
      height: 44px;
      background-color: rgba(164, 46, 46, 1);
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      width: 267px;
      position: absolute;
      text-align: center;
      left: 20px;
      top: 160px;
      line-height: 44px;
      color: #fff;
      font-size: 14px;
    }

    .main6 {
      width: 257px;
      height: 24px;
      position: absolute;
      font-size: 14px;
      top: 230px;
      left: 20px;

      input {
        width: 20px;
        display: inline-block;
        margin-right: 5px;
      }

      a {
        color: rgba(29, 170, 196, 0.81);
        text-decoration: 1px underline;
      }
    }

    .main7 {
      width: 290px;
      height: 24px;
      position: absolute;
      top: 290px;
      left: 8px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .txt {
        position: absolute;
        width: 100%;
        text-align: center;
        font-size: 14px;
      }

      .section {
        width: 106px;
        height: 1px;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng985ff3e208c80d5de9b0f7f53b9530e7b128d9763b9503e02e24fa99e110a202) 100% no-repeat;
      }

    }

    .main8 {
      width: 290px;
      height: 24px;
      position: absolute;
      left: 26px;
      top: 324px;

      .ico {
        width: 24px;
        height: 24px;
        background-size: 100% 100%;
      }

      .alipay {
        background-image: url("https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6888f6f1b6b05c929bd641357b097182ff08aac7846e41eef70592753466cbef");
      }
    }
  }
}

</style>
